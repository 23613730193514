import Typography from "typography"
import CodePlugin from "typography-plugin-code"
import theme from "typography-theme-gutenberg"

import colors from "./colors"

theme.overrideThemeStyles = ({ rhythm }) => ({
  "html, body, h1, h2, h3, h4, h5, h6, p": {
    color: colors.black,
  },
  "html, body": {
    backgroundColor: colors.white,
  },
  a: {
    color: colors.blue,
  },
  "a:visited": {
    color: colors.purple,
  },
  "a:hover": {
    color: colors.green,
  },
  "h1,h2,h3,h4,h5,h6": {
    marginTop: rhythm(1.3),
  },
  ".gatsby-highlight": {
    fontSize: rhythm(0.55),
  },
  "#eareese-noscript": {
    backgroundColor: colors.silver,
    color: colors.black,
    fontSize: rhythm(0.4),
    lineHeight: rhythm(0.7),
    margin: 0,
    padding: `0 ${rhythm(0.2)}`,
  },
})
theme.plugins = [new CodePlugin()]
const typography = new Typography(theme)

// Export helper functions
export const { scale, rhythm, options } = typography
export default typography

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import colors from "../utils/colors"
import { rhythm } from "../utils/typography"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: colors.silver,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: colors.black,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <nav style={{ fontSize: rhythm(0.64), margin: 0 }}>
        <Link to="/">home</Link> | <Link to="/about">about</Link>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
